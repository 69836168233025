import React from 'react';

import { css } from '@emotion/react';
import CoffeeIcon from '@mui/icons-material/Coffee';
import { Button, Link as MuiLink, Typography } from '@mui/material';
import { graphql, HeadProps, PageProps } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

import {
  Layout,
  Seo,
  ContentWrapper,
  Masthead,
  MainMasthead,
  BoxTitle,
} from '@/components';
import { useMetadata } from '@/hooks';

const title = 'Buy me a coffee';
const description = '저에게 커피 한 잔을 후원해주세요.';

const DonationIndex = ({
  data,
}: PageProps<{
  background: ImageDataLike;
}>) => {
  const { background } = data;
  const siteMetadata = useMetadata();

  return (
    <Layout>
      <Masthead background={background}>
        <MainMasthead title={title} description={description} />
      </Masthead>

      <ContentWrapper
        component={'main'}
        css={css`
          padding-top: 3rem;
          padding-bottom: 3rem;
        `}
      >
        <BoxTitle
          title="창작 활동에 대한 자발적 후원을 받고 있습니다"
          description="후원하기"
        />

        <Typography
          variant="body1"
          css={css`
            margin-top: 2rem;
            margin-bottom: 2rem;
          `}
        >
          아래 링크를 클릭하시면 토스를 통해 저에게 1,000원의 후원금을 지불하실
          수 있습니다.
          <br />
          후원금은 더 나은 창작 활동을 위한 커피 금액으로 사용됩니다.
        </Typography>

        <MuiLink
          href={siteMetadata.toss.link}
          target="_blank"
          rel="noopener noreferrer"
          css={css`
            text-decoration: none;
          `}
        >
          <Button
            aria-label="후원하기"
            startIcon={
              <CoffeeIcon
                css={css`
                  color: currentColor;
                `}
              />
            }
          >
            토스로 후원하기
          </Button>
        </MuiLink>
      </ContentWrapper>
    </Layout>
  );
};

export default DonationIndex;

export const backgroundQuery = graphql`
  query {
    background: file(relativePath: { eq: "Donation.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export const Head = ({ location }: HeadProps) => {
  return (
    <Seo title={title} description={description} path={location.pathname} />
  );
};
